import revive_payload_client_baJbZcE5YO from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fg3bTAPf7w from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fAyOvugGgK from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_LNSZ2i782u from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.19.0_vite@5.3.4_@types+node@20.14.11_terser@5_potey4leaiohx5ihcpm5tnz2ba/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_5C8t7N3d2R from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_y3e4W9ahBR from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_qjjXYbIily from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_boKo0sve4K from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/.nuxt/components.plugin.mjs";
import prefetch_client_uglSBynZlX from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.11_eslint@8.57.0_ioredis@5.4.1_magicast@0_ohxjf2kh53d532sel3xch5gsym/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_udKMoSL7nO from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.4_rollup@4.19.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_gAClOEqdCD from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.19.0_vue@3.4.33_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/.nuxt/formkitPlugin.mjs";
import plugin_c4kKvi2Ju1 from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.1.1_graphql-ws@5.16.0_graphql@16.9.0__react-dom@18.3.1_react@_pmjgjdsqtlrcd3asn4msw4ojwi/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.js";
import plugin_uHWp9pREOe from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.9_reac_dzjx3aiiuiiqbbec6aoa53lr5m/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import errors_VmcygObOA7 from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_fczk4xzyogftpxjdsipnxipmpa/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_hfftOieFZc from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.25.0_magicast@0.3.4_nuxt@3.12.4_@parcel+watc_fczk4xzyogftpxjdsipnxipmpa/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_rASURfqvYT from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_asno2e7wd4yirconvm6tzjsx2y/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import sofie_required_version_8AvwZ1qk3M from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.3.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__magi_asno2e7wd4yirconvm6tzjsx2y/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/sofie-required-version.ts";
import apollo_vSHWvv8VcQ from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__mpphigphs7mw2kvrbubmxfbhxq/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/apollo.ts";
import auth_qD28iGuuy7 from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie-auth@1.3.2_@bugsnag+core@7.25.0_graphql-ws@5.16.0_graphql@16.9.0__mpphigphs7mw2kvrbubmxfbhxq/node_modules/@bttr-devs/nuxt-layer-sofie-auth/plugins/auth.ts";
import markerio_client_Dja3mhe4yb from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/plugins/markerio.client.ts";
import notifications_sUFtzNS9eT from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/plugins/notifications.ts";
export default [
  revive_payload_client_baJbZcE5YO,
  unhead_fg3bTAPf7w,
  router_fAyOvugGgK,
  _0_siteConfig_LNSZ2i782u,
  payload_client_5C8t7N3d2R,
  navigation_repaint_client_y3e4W9ahBR,
  check_outdated_build_client_qjjXYbIily,
  chunk_reload_client_boKo0sve4K,
  components_plugin_KR1HBZs4kY,
  prefetch_client_uglSBynZlX,
  plugin_udKMoSL7nO,
  i18n_gAClOEqdCD,
  formkitPlugin_pZqjah0RUG,
  plugin_c4kKvi2Ju1,
  plugin_uHWp9pREOe,
  errors_VmcygObOA7,
  i18n_hfftOieFZc,
  apollo_rASURfqvYT,
  sofie_required_version_8AvwZ1qk3M,
  apollo_vSHWvv8VcQ,
  auth_qD28iGuuy7,
  markerio_client_Dja3mhe4yb,
  notifications_sUFtzNS9eT
]