<template>
  <div>
    <client-only>
      <UiDrawing
        v-bind="drawingAttributes"
        :class="props.context.drawingClass"
        :border="props.context.border"
        @update:model-value="handleInput"
      />
    </client-only>
  </div>
</template>

<script lang="ts" setup>
import type {FormKitFrameworkContext} from '@formkit/core';

const props = defineProps<{
  context: FormKitFrameworkContext
}>();

const attributes = computed(() => Object.assign(props.context?.attrs, {id: null, class: null}));

const drawingAttributes = computed(() => {
  const attributes = {
    canvasId: generateString(10),
    width: props.context.width,
    height: props.context.height,
  };

  if (props.context.image) {
    attributes.image = props.context.image;
  }

  return attributes;
});

function handleInput(value) {
  if (!value.data) {
    return;
  }

  props.context.node.input(value.data);
}

function generateString(length) {
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

  let result = '';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}
</script>
