<template>
  <div>
    <div
      ref="canvasContainer"
      class="overflow-clip"
      :class="{'rounded-xl border border-primary': props.border}"
    >
      <VueDrawingCanvas
        ref="canvasRef"
        :canvas-id="canvasId ? canvasId : 'VueCanvasDrawing'"
        :background-image="image"
        line-cap="round"
        line-join="round"
        :width="width"
        :height="height"
        @mouseup="handleCanvas"
      />
    </div>
    <button
      class="cursor-pointer text-xs text-primary-500 underline"
      type="button"
      @click="clearCanvas"
    >
      Reset
    </button>
  </div>
</template>

<script setup lang="ts">
import VueDrawingCanvas from 'vue-drawing-canvas';
import {ref} from 'vue';

const props = withDefaults(defineProps<{
  canvasId: string
  image?: string
  width: number
  height: number
  border: boolean
}>(), {
  image: null,
  border: true,
});

const modelValue = defineModel('modelValue', {type: String});

const canvasRef = ref();
const canvasContainer = ref(null);

function handleCanvas() {
  modelValue.value = {
    data: canvasRef.value.save(),
    strokes: canvasRef.value.getAllStrokes(),
  };
}

function clearCanvas() {
  canvasRef.value?.reset();
}
</script>
