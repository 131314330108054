import markerSDK from '@marker.io/browser';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  const user = useSofieUserWhenAuthenticated();

  if (!user.value || !config.public.markerIo) {
    return;
  }

  markerSDK.loadWidget({
    project: config.public.markerIo,
  });
});
