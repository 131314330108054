<template>
  <NuxtLayout>
    <PageLayout class="h-full">
      <div class="-mt-12 flex h-full flex-col items-center justify-center gap-6 text-center">
        <UiIcon
          name="Error"
          class="!size-14"
        />
        <div class="text-2xl font-bold text-primary">
          {{ error.statusCode === 404 ? 'Pagina niet gevonden' : error.statusCode }}
        </div>
        <div>
          <template v-if="error.statusCode === 404">
            De pagina die je zoekt bestaat niet. <br>
            Hier zijn enkele nuttige links:
          </template>
          <template v-else>
            {{ error.message }}
          </template>
        </div>

        <div class="grid grid-cols-2 gap-3">
          <UiButton
            variant="outline"
            prefix-icon="ArrowLeft"
            label="Ga terug"
            @click="goBack"
          />
          <UiButton
            label="Naar dashboard"
            @click="handleError"
          />
        </div>
      </div>
    </PageLayout>
  </NuxtLayout>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}

function goBack() {
  history.back();
}
</script>
