<template>
  <component
    :is="to ? NuxtLink : 'button'"
    class="inline-flex cursor-pointer items-center justify-center gap-1.5 rounded-xl text-sm font-medium no-underline transition disabled:cursor-not-allowed disabled:border-0 disabled:opacity-30 disabled:saturate-0 print:hidden"
    :class="[
      ['submit', 'square'].includes(variant) ? colorVariants['primary'] : colorVariants[variant],
      {'size-11 !p-0' : variant === 'square'},
      {'opacity-30 saturate-0' : disabled},
    ]"
    :disabled="disabled || loading ? 'disabled' : undefined"
    :to="localePath(to)"
    :type="type === 'submit' ? 'submit' : 'button'"
  >
    <div
      class="flex items-center justify-center gap-1.5"
      :class="{'-mt-0.5' : variant !== 'square'}"
    >
      <UiIcon
        v-if="prefixIcon"
        :name="prefixIcon"
        :size="iconSize"
        :class="{'text-secondary' : variant !== 'outline'}"
      />
      {{ label }}
      <UiIcon
        v-if="icon"
        :name="icon"
        :size="iconSize"
        :class="{'text-secondary' : variant !== 'outline'}"
      />
      <UiIcon
        v-if="loading"
        :size="iconSize"
        name="svg-spinners:ring-resize"
      />
    </div>
  </component>
</template>

<script lang="ts" setup>
import {NuxtLink} from '#components';
import type {RouteLocationRaw} from 'vue-router';

const colorVariants = {
  primary: 'px-5 pb-2 pt-3 bg-primary-500 hover:bg-primary active:bg-primary-500 active:border-primary-500 text-white border border-primary-700 border-l border-r border-b-4',
  outline: 'p-3 px-5 bg-transparent text-primary hover:bg-primary-200 active:bg-primary-200 active:border-primary-200 border border-primary',
};

withDefaults(defineProps<{
  disabled?: boolean
  icon?: string
  prefixIcon?: string
  label?: string
  loading?: boolean
  iconSize?: 'tiny' | 'small' | 'medium' | 'large'
  to?: RouteLocationRaw,
  variant?: 'primary' | 'outline' | 'square'
  type?: 'submit' | 'button'
}>(), {
  disabled: false,
  icon: undefined,
  iconSize: 'small',
  label: undefined,
  loading: false,
  prefixIcon: undefined,
  prefixIconSize: 'small',
  to: undefined,
  variant: 'primary',
  type: 'button',
});
</script>
