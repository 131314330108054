import { default as indexcFDsXqHHMfMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/index.vue?macro=true";
import { default as setupMY3SP0i855Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/setup.vue?macro=true";
import { default as invitenbzanheVTvMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/invite.vue?macro=true";
import { default as loginLMnUNsjKxvMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/login.vue?macro=true";
import { default as request_45passwordy4AttVWZE8Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/request-password.vue?macro=true";
import { default as _91token_93MhNYb95qpfMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/reset-password/[token].vue?macro=true";
import { default as betaaldkWDq3xxgnqMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/betaald.vue?macro=true";
import { default as factuur1qxMr6DmmbMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/factuur.vue?macro=true";
import { default as indexTwY7EAEi9iMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/index.vue?macro=true";
import { default as inspectierapportR4AONrb4H0Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/inspectierapport.vue?macro=true";
import { default as indexUuMapYMLjqMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/wijzigen/index.vue?macro=true";
import { default as indexow1koXn6SDMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/index.vue?macro=true";
import { default as boekingenHrbtyFWuonMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen.vue?macro=true";
import { default as facturenPlZO6aMqTiMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/facturen.vue?macro=true";
import { default as indexRRdfObVX1lMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/index.vue?macro=true";
import { default as indexQjHEcROKBjMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/index.vue?macro=true";
import { default as indexN8SMmbbhlaMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/pay/index.vue?macro=true";
import { default as thank_45youCatPo8jT28Meta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/pay/thank-you.vue?macro=true";
import { default as wijzigenpK5NeUYMLCMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/wijzigen.vue?macro=true";
import { default as indexD3spXyKYYpMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/index.vue?macro=true";
import { default as offerteszI6GZmaCPAMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes.vue?macro=true";
import { default as profieltQwF4YRQubMeta } from "/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/profiel.vue?macro=true";
export default [
  {
    name: "auth-2fa",
    path: "/auth/2fa",
    meta: indexcFDsXqHHMfMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-2fa-setup",
    path: "/auth/2fa/setup",
    meta: setupMY3SP0i855Meta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/2fa/setup.vue").then(m => m.default || m)
  },
  {
    name: "auth-invite",
    path: "/auth/invite",
    meta: invitenbzanheVTvMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/invite.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginLMnUNsjKxvMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-request-password",
    path: "/auth/request-password",
    meta: request_45passwordy4AttVWZE8Meta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/request-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-reset-password-token",
    path: "/auth/reset-password/:token()",
    meta: _91token_93MhNYb95qpfMeta || {},
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/core/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: boekingenHrbtyFWuonMeta?.name,
    path: "/boekingen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen.vue").then(m => m.default || m),
    children: [
  {
    name: "boekingen-slug-betaald",
    path: ":slug()/betaald",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/betaald.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-slug-factuur",
    path: ":slug()/factuur",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/factuur.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-slug",
    path: ":slug()",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-slug-inspectierapport",
    path: ":slug()/inspectierapport",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/inspectierapport.vue").then(m => m.default || m)
  },
  {
    name: "boekingen-slug-wijzigen",
    path: ":slug()/wijzigen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/[slug]/wijzigen/index.vue").then(m => m.default || m)
  },
  {
    name: "boekingen",
    path: "",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/boekingen/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "facturen",
    path: "/facturen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/facturen.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/index.vue").then(m => m.default || m)
  },
  {
    name: offerteszI6GZmaCPAMeta?.name,
    path: "/offertes",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes.vue").then(m => m.default || m),
    children: [
  {
    name: "offertes-slug",
    path: ":slug()",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "offertes-slug-pay",
    path: ":slug()/pay",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/pay/index.vue").then(m => m.default || m)
  },
  {
    name: "offertes-slug-pay-thank-you",
    path: ":slug()/pay/thank-you",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/pay/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "offertes-slug-wijzigen",
    path: ":slug()/wijzigen",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/[slug]/wijzigen.vue").then(m => m.default || m)
  },
  {
    name: "offertes",
    path: "",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/offertes/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "profiel",
    path: "/profiel",
    component: () => import("/data/www/jouw.goroadtrip.nl/go-roadtrip-dashboard/nuxt/customer/pages/profiel.vue").then(m => m.default || m)
  }
]