import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAngleRight, LazyArrowLeft, LazyArrowRight, LazyAudio, LazyBirthdayCake, LazyBookings, LazyCalendar, LazyCar, LazyCheckCircle, LazyChevronDown, LazyChevronLeft, LazyChevronRight, LazyDroplet, LazyEnvelope, LazyError, LazyEuroSign, LazyExternalLink, LazyExtras, LazyEye, LazyFile, LazyFileAcrobat, LazyFileCsv, LazyHamburger, LazyHome, LazyInfo, LazyLogout, LazyMapMarker, LazyMoneyEnvelope, LazyPaperPlane, LazyPencil, LazyPhone, LazyPlus, LazyQuotations, LazyReceipt, LazySnowflake, LazySort, LazySortDown, LazySortUp, LazySuitcase, LazyTransmission, LazyUser, LazyXMark, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["AngleRight", LazyAngleRight],
["ArrowLeft", LazyArrowLeft],
["ArrowRight", LazyArrowRight],
["Audio", LazyAudio],
["BirthdayCake", LazyBirthdayCake],
["Bookings", LazyBookings],
["Calendar", LazyCalendar],
["Car", LazyCar],
["CheckCircle", LazyCheckCircle],
["ChevronDown", LazyChevronDown],
["ChevronLeft", LazyChevronLeft],
["ChevronRight", LazyChevronRight],
["Droplet", LazyDroplet],
["Envelope", LazyEnvelope],
["Error", LazyError],
["EuroSign", LazyEuroSign],
["ExternalLink", LazyExternalLink],
["Extras", LazyExtras],
["Eye", LazyEye],
["File", LazyFile],
["FileAcrobat", LazyFileAcrobat],
["FileCsv", LazyFileCsv],
["Hamburger", LazyHamburger],
["Home", LazyHome],
["Info", LazyInfo],
["Logout", LazyLogout],
["MapMarker", LazyMapMarker],
["MoneyEnvelope", LazyMoneyEnvelope],
["PaperPlane", LazyPaperPlane],
["Pencil", LazyPencil],
["Phone", LazyPhone],
["Plus", LazyPlus],
["Quotations", LazyQuotations],
["Receipt", LazyReceipt],
["Snowflake", LazySnowflake],
["Sort", LazySort],
["SortDown", LazySortDown],
["SortUp", LazySortUp],
["Suitcase", LazySuitcase],
["Transmission", LazyTransmission],
["User", LazyUser],
["XMark", LazyXMark],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
